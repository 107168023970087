import React from 'react';

// import i18n from '../../i18n';
// import PropTypes from 'prop-types';
import styles from './ArticleBlock.module.scss';
import Article from '../Article';

const ArticleBlock = (article = {}) => (
    <div className={styles['ArticleBlock']}>
        <div className={styles['ArticleBlock__Wrapper']}>
            <Article {...article} />
        </div>
    </div>
);

ArticleBlock.propTypes = {};

export default ArticleBlock;
